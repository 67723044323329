.map {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin: 20px;
  user-select: none;
  aspect-ratio: 32/11;
  box-sizing: border-box;
  justify-self: center;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 40px);

  img {
    width: 100%;
    height: 100%;
    image-rendering: pixelated;
  }
}
