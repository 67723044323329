.ribbon {
  position: fixed;
  z-index: 9999;
  width: 80px;
  height: 80px;
  pointer-events: none;
  user-select: none;
  opacity: 1;
  transform: rotate(45deg);
  transition: opacity 0.15s ease-in-out;
  backface-visibility: hidden;
}

.ribbon:hover {
  opacity: 0.75;
}

.content {
  font: bold 15px Sans-Serif;
  text-align: center;
  position: relative;
  padding: 7px 0;
  width: 150px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  pointer-events: all;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  background-color: #fafafa;
  color: #333;
}

.ribbon.bottom.right,
.ribbon.top.left {
  transform: rotate(-45deg);
}
.ribbon.bottom {
  bottom: -10px;
}
.ribbon.top {
  top: -10px;
}
.ribbon.left {
  left: -10px;
}
.ribbon.right {
  right: -10px;
}
