.menu {
  z-index: 9999;
  position: absolute;
  left: 100%;
  margin-left: 5px;
  background: black;
  padding: 0 10px;
  border-radius: 10px;

  top: 0;
  opacity: 0;

  transition: 0.15s ease-in-out;
  transition-property: top opacity;

  &:not(.opened) {
    pointer-events: none;
  }

  &.opened {
    opacity: 1;
    top: 3px;
  }
}
