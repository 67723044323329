.container {
  position: relative;
}

.tile {
  user-select: none;
  outline: 1px solid rgba(0, 0, 0, 0.15);
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;

  &.disabled {
    pointer-events: none;
  }

  &.visible {
    opacity: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.25);

    transition: 0.15s ease-in-out;
    transition-property: opacity;
    opacity: 0;
  }

  &:hover:after {
    opacity: 1;
  }
}
