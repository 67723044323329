.slider {
  --background: rgba(0, 0, 0, 0.35);
  --size: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: linear-gradient(to right, var(--background) var(--size), transparent var(--size)),
    linear-gradient(to bottom, var(--background) var(--size), transparent var(--size)),
    linear-gradient(to right, var(--background) var(--size), transparent var(--size)),
    linear-gradient(to bottom, var(--background) var(--size), transparent var(--size)),
    linear-gradient(to bottom, transparent var(--size), var(--background) var(--size));
  background-size: calc(var(--size) * 2) var(--size), calc(var(--size) * 2) var(--size),
    calc(var(--size) * 2) var(--size), calc(var(--size) * 2) var(--size), 100% calc(100% - var(--size) * 3);
  background-repeat: repeat-x;
  background-position: 0 var(--size), top left, 0 calc(100% - var(--size)), bottom left, 0 var(--size);
  padding: calc(var(--size) * 3) calc(var(--size) * 2.5);
  box-sizing: border-box;

  .screen {
    position: relative;
    display: grid;
    user-select: none;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(11, 1fr);
    gap: 1px;

    // width: 256px;
    height: 100%;
    user-select: none;
    image-rendering: pixelated;
    aspect-ratio: 28/19;
    zoom: 2;
    overflow: hidden;
    background-color: #fcd9a9;
    background-size: 1600% 800%;
    border: 4px solid #272727;

    transition: 0.15s ease-in-out;
    transition-property: background-position-x background-position-y;
  }

  &.visible .screen {
    opacity: 1;
  }
}
